import { Link } from "gatsby";
import { useEffect, useState } from "react";
import { format } from "date-fns";

import {
  PDFViewer,
  Page,
  Text,
  Image,
  View,
  Document,
  StyleSheet,
} from "@react-pdf/renderer";

import { Routes, Stored } from "~/models";
import { useStoredQuestions } from "~/utils";
import { Meta, Layout, Section } from "~/ui";
import type { YouInferredType } from "~/components";

import certificateImg from "~/assets/images/volunteer-certificate.png";

const ISSUING_ORGANISATION = "Y Volunteer";
const COURSE_NAME = "Volunteer Ready Modules";

export default function VolunteerReadyCertificate(): JSX.Element | null {
  const { areModulesCompleted, dateCompleted } = useStoredQuestions();
  const [participantName, setParticipantName] = useState<string>();
  const [isLoading, setIsLoading] = useState<boolean>(true);

  // Get participant name from local storage... if it exists
  const getParticipantName = (): string | undefined => {
    const localStorageItem = localStorage.getItem(Stored.You);
    if (localStorageItem) {
      const { fullName } =
        (JSON.parse(localStorageItem) as YouInferredType) || {};
      setParticipantName(fullName);
      return fullName;
    }
  };

  // Get values from local storage on load.
  useEffect(() => {
    if (areModulesCompleted) {
      const name = getParticipantName();
      if (!name && !participantName) {
        const promptName = prompt("Please enter your name: ");
        if (promptName) {
          setParticipantName(promptName);
        }
      }
    }
    setIsLoading(false);
  }, [areModulesCompleted]);

  if (isLoading) {
    return null;
  }

  if (!areModulesCompleted) {
    return (
      <Layout backgroundColor="dark">
        <Section bgColor="transparent">
          <div className="row">
            <div className="col-12 col-md-8 mb-4">
              <h1 className="d-flex flex-column text-light mb-4">
                Volunteer Ready
                <span className="display-1">Resources</span>
              </h1>
              <p className="lead text-light mb-4">
                After you have completed the volunteering modules, you can
                download our volunteering ready certificate.
              </p>
              <div className="text-center text-md-start">
                <Link
                  to={Routes.GettingReady}
                  className="btn btn-lg btn-success"
                >
                  Get started
                </Link>
              </div>
            </div>
          </div>
        </Section>
      </Layout>
    );
  }

  return (
    <PDFViewer style={{ width: "100vw", height: "100vh" }}>
      <Document title="Certificate">
        <Page
          object-fit="fill"
          size="A4"
          orientation="landscape"
          style={styles.page}
        >
          <View style={styles.fullpageView}>
            <Image style={styles.image} src={certificateImg} />
            <View style={styles.mainContent}>
              <Text style={styles.heading}>{participantName}</Text>
              <Text style={[styles.heading, styles.muted]}>
                has successfully completed
              </Text>
              <Text style={[styles.heading, styles.bold]}>{COURSE_NAME}</Text>
            </View>
            <Text style={[styles.text, styles.dateIssued]}>
              {dateCompleted
                ? format(dateCompleted, "dd MMMM yyyy")
                : format(new Date(), "dd MMMM yyyy")}
            </Text>
            <Text style={[styles.text, styles.issuedBy]}>
              {ISSUING_ORGANISATION}
            </Text>
          </View>
        </Page>
      </Document>
    </PDFViewer>
  );
}

export const Head = () => {
  return (
    <Meta title="Volunteer Ready" url={Routes.VolunteerReadyCertificate} />
  );
};

const styles = StyleSheet.create({
  page: {
    flexDirection: "row",
    backgroundColor: "#fff",
    width: "100%",
    orientation: "landscape",
  },
  fullpageView: {
    position: "relative",
    width: "100%",
    height: "100%",
    padding: 0,
    backgroundColor: "white",
  },
  image: {
    objectFit: "cover",
    position: "absolute",
    top: "0",
    right: "0",
    bottom: "0",
    left: "0",
    width: "100%",
    height: "100%",
  },
  mainContent: {
    position: "absolute",
    top: "44%",
    left: "31%",
  },
  heading: {
    fontSize: 25,
    lineHeight: 2,
  },
  dateIssued: {
    position: "absolute",
    top: "78%",
    left: "31%",
  },
  issuedBy: {
    position: "absolute",
    top: "78%",
    left: "60%",
  },
  text: {
    fontSize: 14,
    fontWeight: 500,
  },
  bold: {
    fontWeight: 700,
  },
  muted: {
    color: "#6c757d",
  },
});
